:root {
  --sales: #007bff;
  --purchase: #6610f2;
  --collection: #17a2b8;
  --due: #dc3545;
  --loss: #dc3545;
  --profit: #28a745;

  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dde0e2;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Imported;
  src: url(./components/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Source Sans Pro";
  src: url(./components/fonts/SourceSansPro-Regular.ttf);
}

#root {
  font-family: Imported;
}

.hidden {
  display: hidden;
}

/* CUSTOM CSS @test purpose */

.bg-primary {
  background-color: #18b4e9;
}

.text-primary {
  color: #18b4e9;
}

.border {
  background-color: #7a7a7a1a;
  border-radius: 7px;
}

.p-5 {
  padding: 10px;
}

.p-10 {
  padding: 20px;
}

.my-20 {
  margin: 40px 0;
}

.mt-5 {
  margin-top: 10px;
}

.mt-10 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.p-25 {
  padding: 25px;
}

.p-20 {
  padding: 20px;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.text-green {
  color: var(--green);
}

.text-red {
  color: var(--red);
}

.text-sales {
  color: var(--sales);
}

.text-purchase {
  color: var(--purchase);
}

.text-collection {
  color: var(--collection);
}

.text-due {
  color: var(--due);
}

.text-profit {
  color: var(--profit);
}

.uppercase {
  text-transform: uppercase;
}

.secondary-color {
  color: lightgray;
}

.text-white {
  color: #fff;
}

.resWidht {
  width: 400px;
}

.refund-row-style {
  background-color: rgba(255, 99, 71, 0.089) !important;
}

.success-row-style {
  background-color: rgba(71, 255, 148, 0.089) !important;
}

.record-edit-active {
  background-color: #e6f4ff !important;
}

.deleted-edit-active {
  background-color: #ff80004d !important;
}

.text-center {
  text-align: center;
}

.invoiceBillingTable .invoiceBillingTd > td {
  padding: 4px !important;
  border-bottom: 1px solid lightgray;
  font-size: 12px !important;
}

.invoiceBillingTable .ant-table-thead .ant-table-cell {
  padding: 5px 7px !important;
  font-size: 12px !important;
  border-bottom: 1px solid lightgray;
}

/* common antd table */

.ant-table-tbody > tr > td {
  font-size: 13px !important;
}

.ant-table-thead > tr > th {
  padding: 10px !important;
  background-color: #174a571f !important;
}

.ant-table-wrapper tfoot > tr > td {
  padding: 2px !important;
  text-align: center !important;
}

.table-column {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
}

.table-column-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  padding: 0px !important;
  margin: 0px !important;
}

.changeFont {
  font-family: "Source Sans Pro", sans-serif !important;
  size: 12px;
}

.changeFontBlock {
  font-family: "Source Sans Pro", sans-serif !important;
  display: block;
  size: 13px;
}

/* Changing page color to white while print */
@media print {
  @page {
    margin: 0 !important;
    /* size: A4 !important; */
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  tfoot {
    display: table-row-group;
  }

  .scrollBarVisible {
    overflow: visible !important;
  }

  span.text-white {
    color: white;
  }

  .print-only {
    display: block;
    /* Show the content when printing */
  }

  .no-margin {
    margin: 0 !important;
  }

  .ant-table {
    background-color: white !important;
    padding: 5000px;
  }

  th.ant-table-cell {
    color: rgb(14, 14, 14) !important;
  }

  .ant-table td {
    color: black !important;
  }

  td.ant-table-cell span {
    color: black;
  }

  tfoot.ant-table-summary {
    background-color: white !important;
  }

  ul.ant-pagination.ant-table-pagination {
    display: none;
  }

  .ant-card-body {
    background-color: white;
  }

  .ant-typography {
    color: black;
  }

  h3.ant-typography,
  h4.ant-typography,
  h5.ant-typography {
    color: black;
  }
}

/* end print */

.ant-switch .ant-switch-handle {
  width: 20px;
  height: 20px;
  inset-inline-start: 0;
  padding: 2px;
}

/* Sidebar scrollbar design customize */
.siderDesign {
  overflow-y: scroll !important;
  position: relative;
}

/* Chrome */
.siderDesign::-webkit-scrollbar {
  position: absolute !important;
  right: 0 !important;
  width: 6px;
}

/* Styles for the scroll bar thumb (the draggable portion) */
.siderDesign::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 6px;
  position: absolute;
}

.siderDesign:hover::-webkit-scrollbar-thumb {
  background-color: #b8b5b5;
  border-radius: 5px;
  position: absolute;
}

/* Styles for the scroll bar track (the area behind the thumb) */
.siderDesign::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
  position: absolute;
}

/* For Firefox */
.siderDesign {
  scrollbar-width: thin;
  scrollbar-color: #b8b5b5 #f1f1f1;
}

.siderDesign::-moz-scrollbar-thumb {
  background-color: #b8b5b5;
  border-radius: 5px;
}

.siderDesign::-moz-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.highlight_row {
  animation: blinkBackground 1s 5;
  background-color: lightblue;
}

@keyframes blinkBackground {
  0% {
    background-color: lightblue;
  }

  50% {
    background-color: transparent;
  }

  100% {
    background-color: lightblue;
  }
}

.new-label {
  position: relative;
  padding: 1px 5px;
  border-radius: 5px;
  background-color: lightseagreen;
  color: white;
}

.rowColor1 {
  background-color: #fafafa;
}

.rowColor2 {
  background-color: #e9e4e4;
}

.icon_hover:hover {
  filter: blur(0.8px);
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.justify-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-size-large {
  font-size: 25px;
}

.ql-size-small {
  font-size: 14px;
}

.ql-size-medium {
  font-size: 18px;
}

.ql-size-extra-small {
  font-size: 10px;
}

.hover_effect {
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

/* DISPLAY STYLES */
.d-block {
  display: block;
}

.font-bold {
  font-weight: bold;
}

.font-warning {
  color: #f50;
}

.font-success {
  color: #87d068;
}

.report_total_footer {
  color: green;
  font-weight: 700;
  text-align: right;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 0px 0px !important;
}

.bold {
  font-weight: 600;
}

.height-500 {
  height: 250px;
}
